<template>
    <div>
        <header-portrate />
    
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />

    <v-form ref="addpr">
        <v-row class="m-4" style="border:1px solid #ccc;background:#eee;border-radius:5px;margin-left:30px !important;margin-right:30px !important;">
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.item_code}}</label>
                <b-form-input
                    v-model="newrow.itemcode"
                    @change="getit()"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{lang.item_name}}</label>
                <b-form-input
                    v-model="newrow.name"
                    :rules="fieldRules"
                    class="inborder"
                    required
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.product_categories}}</label>
                <b-form-select
                    item-text="text"
                    item-value="value"
                    v-model="newrow.catid"
                    :options="product_categories"
                    class="selborder"
                    ></b-form-select>
            </v-col>
            <v-col cols="12" md="1" sm="12">
                <label>{{lang.price}}</label>
                <b-form-input
                    :label="lang.item_price"
                    v-model="newrow.price"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="1" sm="12">
                <label>{{lang.qtty}}</label>
                <b-form-input
                    v-model="newrow.qty"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="mt-4">
                <b-button variant="success" style="width:145px;margin-right:6px;" class="btn-sm" @click="addIt()">{{lang.add}} / {{lang.update}}</b-button>
            </v-col>
        </v-row>
    </v-form>
        <v-row class="m-2" style="margin-left:30px !important;margin-right:30px !important;">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:10%;" class="text-center">{{ row.item.itemcode }}</td>
                            <td style="width:60%;" class="text-start" nowrap>{{ row.item.name }}</td>
                            <td style="width:60%;" class="text-start" nowrap>{{ row.item.category_name }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.price }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.qty }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.ftotal }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
    </div>
    <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
export default{
  components: { breadCrumbs, TabsComp, Footer, HeaderPortrate },
    name: 'Products',
    data() {
        return {
            active_tab:0,
            tablerows: [

            ],
            newrow: {
                itemcode: '',
                name: '',
                price: '',
                qty: '',
                catid: '',
            },
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            product_categories: [],
        }
    },
    created() {
        this.getProducts();
        this.getCats();
    },
    methods: {
        getCats(){
            const post = new FormData();
            post.append("type","getPCatsList");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all0");
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                // console.log('asdfasdf',p);
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push({
                                text: this.lang.langname == "ar" ? p.results.data[i].namear : p.results.data[i].nameen,
                                value: p.results.data[i].id
                            });
                        }
                    }
                }
                this.product_categories = t;
            });
        },
        addIt() {
            if(!this.$refs.addpr.validate()){
                // this.$snotify.error('يجب عليك ان تقوم باضافة اسم القطعة', '', { 
                //         timeout: 2000,
                //         showProgressBar: true,
                //         closeOnClick: false,
                //         pauseOnHover: true,
                //         leftTop:"leftTop"
                //     });
                return false
            }
            const post = new FormData();
            post.append("type","addProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode);
            post.append('data[name]',this.newrow.name);
            post.append('data[price]',this.newrow.price);
            post.append('data[qty]',this.newrow.qty);
            post.append('data[catid]',this.newrow.catid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                //// console.log(res.data);
                this.$snotify.success('تم اضافة / تعديل الصنف بنجاح', '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                this.newrow = {
                        itemcode: '',
                        name: '',
                        price: 0,
                        qty: 0,
                        catid: '',
                    }
                return false;
            }).then((res) => {this.getProducts();})
            .then((res) => {this.calcTo()})
        },
        getit() {
            for(let i=0;i<this.tablerows.length;i++){
                if(this.tablerows[i].itemcode == this.newrow.itemcode){
                    this.newrow.name = this.tablerows[i].name;
                    this.newrow.price = this.tablerows[i].price;
                    this.newrow.qty = this.tablerows[i].qty;
                    this.newrow.catid = this.tablerows[i].catid;
                }
            }
        },
        calcTo () {
            const xy = this.$store.state.products;
            // this.tablerows = this.$store.state.products;
            const p = [];
            for(let i=0;i<xy.length;i++){
                const ss = parseFloat(xy[i].price) * parseFloat(xy[i].qty);
                const y = {
                    itemcode: xy[i].itemcode,
                    name: xy[i].name,
                    qty: xy[i].qty,
                    price: xy[i].price,
                    ftotal: this.$RoundNum(ss),
                    category_name: xy[i].category_name,
                    catid: xy[i].catid,
                };
               p.push(y);
            }
            this.tablerows = p;
        },
        getProducts(){
          const post = new FormData();
          post.append('type','getProducts');
          post.append('auth',this.$cookies.get(this.$COOKIEPhase));
          post.append('data[ids]','0');
          post.append('data[lang]',this.lang.langname);
          this.$store.state.products = [];
            axios.post(this.$SAMCOTEC.r_path,post)
          .then((response) => {
              const res = response.data;
              if(res.results.data.length > 0){
                  this.$store.state.products = res.results.data;
              }
              this.calcTo ();
          });
      },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                {
                    text: this.lang.item_code,
                    align: 'center',
                    filterable: false,
                    value: 'itemcode',
                },
                {
                    text: this.lang.item_name,
                    align: 'center',
                    filterable: false,
                    value: 'name',
                },
                {
                    text: this.lang.category_name,
                    align: 'center',
                    filterable: false,
                    value: 'price',
                },
                {
                    text: this.lang.item_price,
                    align: 'center',
                    filterable: false,
                    value: 'price',
                },
                {
                    text: this.lang.qtty,
                    align: 'center',
                    filterable: false,
                    value: 'qty',
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                },
            ]
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.vendors,href:'/inventory/vendors',class:'mytab3',
                },
                {
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab5',
                },
                {
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab1'
                },
                {
                    index:2,name:this.lang.products,href:'/inventory/products', class: 'mytab'
                },
                {
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab2'
                },
                {
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab3'
                },
                {
                    index:5,name:'الاصناف المنتهية',href:'/inventory/finished', class: 'mytab4'
                },
                {
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4'
                }
            ]
        },
        items: function() {
            return {
                    text: this.lang.inventory,
                    disabled: true,
                    to: '/inventory/products',
                }
        }
    },
}
</script>